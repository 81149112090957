
  .modal {

    &__content {
      margin-left: 90px;
      margin-right: 90px;

      &--body {
        height: 350px;
        overflow-x: hidden;
        overflow-y: scroll;
      }

      &--collapse {
        margin-left: 0;
        margin-right: 0;
      }

      &--header {
        padding-top: 100px;
        margin: 0;
      }
    }

    &__draggable-item {
      background: #fff;
      border: 1px solid #E0E0E0;
      color: #4F4F4F;
      cursor: move;
      margin: 0 0 9px;
      padding: 5px 12px;
      position: relative;
      text-transform: capitalize;
    }

    &__draggable-icon {
      bottom: 0;
      height: 20px;
      margin: auto;
      position: absolute;
      right: 10px;
      top: 0;
      width: 20px;
    }
  }
